/* index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
} */

@layer utilities {
  /* Custom scrollbar styles */
  .scrollbar-thin::-webkit-scrollbar {
    height: 6px; /* Adjust the scrollbar height */
  }
  .scrollbar-thin::-webkit-scrollbar-track {
    background: transparent; /* Make the track transparent */
  }
  .scrollbar-thin::-webkit-scrollbar-thumb {
    background-color: transparent; /* Default invisible */
    border-radius: 9999px; /* Make the scrollbar rounded */
  }
  .scrollbar-thin:hover::-webkit-scrollbar-thumb {
    background-color: #b0b0b0; /* Show scrollbar when hovering */
  }
}
